<template>
  <div class="operation">
    <!-- 修改默认密码新密码 -->
    <div v-if="status == 0">
      <div class="title">重置新密码</div>
      <div class="field-box" style="margin: 50px 0">
        <van-field
          v-model="newPassword"
          type="password"
          placeholder="请输入新密码"
        />
      </div>
      <div class="field-box">
        <van-field
          v-model="confirmNewPassword"
          type="password"
          placeholder="请再次输入新密码"
        />
      </div>
      <div
        class="gradient-background"
        style="margin-top: 100px"
        @click="status = 1"
      >
        下一步
      </div>
      <div style="text-align: center">
        <div class="pass" @click="back">跳过</div>
      </div>
    </div>

    <!-- 请补充个人信息 -->
    <div v-if="status == 1">
      <div class="title">请补充个人信息</div>
      <div class="field-box">
        <p>账号</p>
        <van-field v-model="newPassword" placeholder="请输入邮箱" />
      </div>
      <div class="field-box">
        <p>真实姓名</p>
        <van-field v-model="newPassword" placeholder="请输入真实姓名" />
      </div>
      <div class="field-box">
        <p>身份证</p>
        <van-field v-model="newPassword" placeholder="请输入身份证" />
      </div>
      <div class="field-box">
        <p>所在部门</p>
        <van-field v-model="newPassword" placeholder="请输入新密码" />
      </div>
      <div class="gradient-background" @click="status = 2">下一步</div>
      <div style="text-align: center">
        <div class="pass" @click="back">跳过</div>
      </div>
    </div>

    <!-- 请补充身份证信息 -->
    <div v-if="status == 2">
      <div class="title">请补充您的身份证信息</div>
      <div class="field-box no-border">
        <p>身份证人像照片</p>
        <van-uploader :after-read="afterRead">
          <div style="text-align: center">
            <img src="../../assets/imgs/id_01.png" alt="" style="width: 100%" />
          </div>
        </van-uploader>
      </div>
      <div class="field-box no-border">
        <p>身份证国徽照片</p>
        <van-uploader :after-read="afterRead">
          <img src="../../assets/imgs/id_02.png" alt="" style="width: 100%" />
        </van-uploader>
      </div>
      <div class="gradient-background" @click="submit">提交</div>
      <div style="text-align: center">
        <div class="pass" @click="back">跳过</div>
      </div>
    </div>

    <div v-if="status == 3" style="text-align: center">
      <div class="gradient-background result">
        <i class="el-icon-check"></i>
      </div>
      <div class="title">信息补充完整</div>
      <div class="gradient-background" @click="back">返回首页</div>
    </div>

    <!-- 忘记密码密码 -->
    <div v-if="status == 4">
      <div class="title">请填写您的修改密码信息</div>
      <div class="field-box">
        <p>邮箱</p>
        <van-field v-model="newPassword" placeholder="请输入邮箱" />
      </div>
      <div class="field-box">
        <p>验证码</p>
        <van-field v-model="newPassword" placeholder="请输入验证码" />
        <div
          class="gradient-border code"
          :class="{ 'disabled-click': codeLoading }"
          @click="getcode"
        >
          获取验证码<span v-if="codeLoading">({{ codeLoading }}S)</span>
        </div>
      </div>
      <div class="field-box">
        <p>新密码</p>
        <van-field v-model="newPassword" placeholder="请输入新密码" />
      </div>
      <div class="field-box">
        <p>确认新密码</p>
        <van-field v-model="newPassword" placeholder="请再次输入新密码" />
      </div>
      <div class="gradient-background" @click="status = 5">确认</div>
    </div>

    <div v-if="status == 5" style="text-align: center">
      <div class="gradient-background result">
        <i class="el-icon-check"></i>
      </div>
      <div class="title">修改密码成功</div>
      <div class="gradient-background" @click="reLogin">立即登录</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "operation",
  data() {
    return {
      status: 4,
      newPassword: "",
      confirmNewPassword: "",
      mail: "",
      fullName: "",
      id: "",
      department: "",
      codeLoading: 0,
    };
  },
  methods: {
    reLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    getcode() {
      if (this.codeLoading) return;
      this.codeLoading = 59;
      let timer = setInterval(() => {
        if (this.codeLoading) {
          this.codeLoading--;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    submit() {
      this.status = 3;
    },
    back() {
      this.$router.push({
        path: "/mobileCurriculum",
      });
    },
  },
};
</script>

<style lang="less" scope>
.operation {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 50px;
  color: #909296;
  font-size: 18px;
  line-height: 1.5;
  overflow: auto;
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    line-height: 50px;
    margin-bottom: 50px;
  }
  .no-border {
    border: none !important;
  }
  .field-box {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    position: relative;
  }
  .gradient-background {
    margin-top: 50px;
  }
  .pass {
    margin-top: 20px;
    display: inline-block;
    border-bottom: 1px solid #909296;
    cursor: pointer;
  }
  .result {
    text-align: center;
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    line-height: 100px;
    color: #fff;
    font-weight: bold;
    font-size: 50px;
    margin: 100px 0;
  }
  .code {
    display: inline-block;
    line-height: 36px !important;
    position: absolute;
    right: 0;
    bottom: 5px;
    padding: 0 5px;
    font-size: 12px;
  }
  .disabled-click {
    cursor: not-allowed;
    color: #dcdfe6;
    border: 1px solid #dcdfe6;
  }
}
</style>
